<template>
  <div class="map" v-loading="loading">

    <el-dialog
      v-loading="loading"
      :visible="viewModal"
      :before-close="closeModal"
      :close-on-click-modal="false"
      width="80%"
      class="modal"
    >
      <router-view></router-view>
    </el-dialog>

    <el-dialog
      v-loading="loading"
      :visible="listModal"
      :before-close="closeListModal"
      :close-on-click-modal="false"
      width="50%"
      class="modal"
    >
      <el-row v-if="modalImages">
        <el-col :md="6" :sm="4" :xs="8"
          v-for="(asset,index) in modalImages"
          :key="index"
        >
          <div class="artwork" @click="loadModal(asset.id)">

            <el-image
              v-if="asset.filetype == 'image'" 
              class="artwork"
              :src="asset.image_blob"
              fit="cover"
              lazy
            ></el-image>
            
            <el-image
              v-if="asset.filetype == 'video'" 
              class="artwork"
              :src="asset.video_thumb"
              fit="cover"
              lazy
            ></el-image>

          </div>
        </el-col>
      </el-row>

      <div class="pagination">
        
        <el-pagination
          layout="prev, pager, next"
          :page-size="12"
          :total="modalTotalImages"
          @current-change="pagination"
        >
        </el-pagination>
      </div>

    </el-dialog>

    <el-card class="filters">

      <el-collapse v-model="activeCollapse" accordion>
        
        <h5>Showing</h5>
        {{ this.totalArtwork }} Artworks <br/><br/>
        <hr/>
        
        <el-collapse-item name="1">

          <template slot="title">
            <div>
              <filterIcon/> Filters 
            </div>
          </template>
          
          <h5>Type</h5>
          <el-checkbox-group class="checkbox-list" v-model="mapFilters.filetype" @change="setFiltersFile">
            <el-checkbox class="checkbox-label" v-for="filetype in filetypes" :label="filetype" :key="filetype">
              <component :is="(filetype === 'image') ? 'imageIcon' : 'videoIcon'" /> {{ filetype }}
            </el-checkbox>
          </el-checkbox-group>

          <h5>Charities</h5>
          <el-checkbox-group class="checkbox-list" v-model="mapFilters.charity" @change="setFiltersCharity">
            <el-checkbox class="checkbox-label" v-for="cha in charities" :label="cha.id" :key="cha.id">
              {{ cha.name }}
            </el-checkbox>
          </el-checkbox-group>
          
          <h5>Emotions</h5>
          <el-checkbox-group class="checkbox-list" v-model="mapFilters.mood" @change="setFiltersMood">
            <el-checkbox class="checkbox-label" v-for="mood in mood" :label="mood" :key="mood">
              <component :is="mood" /> {{ mood }}
            </el-checkbox>
          </el-checkbox-group>
          
          <h5>Ratings</h5>
          <el-checkbox-group class="checkbox-list" v-model="mapFilters.rating" @change="setFiltersRating">
            <el-checkbox class="checkbox-label" v-for="rating in ratings" :label="rating.value" :key="rating.label">
              {{ rating.label }}
            </el-checkbox>
          </el-checkbox-group>
          
        </el-collapse-item>
      </el-collapse>

    </el-card>

    <GmapMap 
      v-if="points"
      ref="map"
      class="map"
      :options="mapOptions"
      :center="this.center"
      :zoom="3"
      map-type-id="terrain" 
    >
    </GmapMap>

  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

  import GmapCustomMarker from 'vue2-gmap-custom-marker';

  import filterIcon from "@/assets/filter/filter.svg"
  import videoIcon from "@/assets/filter/video.svg"
  import imageIcon from "@/assets/filter/image.svg"

  import excited from "@/assets/mood/excited.svg"
  import happy from "@/assets/mood/happy.svg"
  import surprised from "@/assets/mood/surprised.svg"
  import bored from "@/assets/mood/bored.svg"
  import sad from "@/assets/mood/sad.svg"
  import angry from "@/assets/mood/angry.svg"
  
  /* VUE2-GOOGLE-MAPS
  ** https://www.npmjs.com/package/vue2-google-maps
  ** https://cloud.google.com/maps-platform/
  */
  import * as VueGoogleMaps from "vue2-google-maps"
  import GmapCluster from 'vue2-google-maps/dist/components/cluster'
  import { MarkerClusterer } from "@googlemaps/markerclusterer";
  import {gmapApi} from 'vue2-google-maps'
  import mapStyles from '@/assets/mapStyles.json'

  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLEMAPS_API_KEY,
      libraries: 'places'
    }
  });

  Vue.component('cluster', GmapCluster)
  
  export default {

    components: {
      GmapCustomMarker,
      filterIcon,
      videoIcon,
      imageIcon,
      excited,
      happy,
      surprised,
      bored,
      sad,
      angry
    },
    
    data() {
      return {
        activeCollapse: null,
        filetypes: ['image'],
        ratings: [
          { value:1, label:'1 Heart' },
          { value:2, label:'2 Hearts' },
          { value:3, label:'3 Hearts' },
          { value:4, label:'4 Hearts' },
          { value:5, label:'5 Hearts' }
        ],
        
        center: { lat: 38.7436883, lng: -9.1952225 },
        
        mapOptions: {
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: true,
          draggable: true,
          styles: mapStyles
        },

        currentLocation : {}
      }
    },

    mounted () {
      this.fetchPoints();
      this.getAllCharities();

      // this.$refs.map.$mapPromise.then((map) => {
      //   map.panTo( {lat:this.points[0].lat, lng:this.points[0].lng })
      // })
    },

    watch: {
      $route: {
        immediate: true,
        handler: function() {
          if (this.$route.params.assetId) {
            this.getAssetDetails(this.$route.params.assetId)
            this.openViewModal()
          } else {
            this.closeViewModal()
          }
        }
      },
      points: {
        immediate: true,
        handler: function() {
          if(this.$refs.map && this.$refs.map.$mapPromise){
            this.$refs.map.$mapPromise.then(() => {
              this.$refs.map.$mapObject;

              const markers = this.points.map((point) => {
              
                let marker;
                
                if(point.count > 1){
                  const image = {
                    url: require("@/assets/marker.png"),
                    labelOrigin: new this.google.maps.Point(0, 50),
                  };

                  const label = {
                    text: "" + point.count,
                    className: "counterMarker",
                    color: "white",
                  }

                  marker = new this.google.maps.Marker({
                    position: { lat: point.lat, lng: point.lng },
                    icon: image,
                    label: label,
                  });
                }else{

                   marker = new this.google.maps.Marker({
                    position: { lat: point.lat, lng: point.lng },
                    icon: require("@/assets/marker.png"),
                  });
                }
                 
                this.addMarkerClickListener(marker, point);

                return marker;
              });
       
              let google = this.google;

              const clusterImage = {
                    url: require("@/assets/marker.png"),
                    labelOrigin: new this.google.maps.Point(0, 50),
                  };

              const clusterLabelLarge = function(length) {
                return {
                  text: "" + length,
                  className: "counterClusterMarkerLarge",
                  color: "white",
                }
              };

              const clusterLabelSmall = function(length) {
                return {
                  text: "" + length,
                  className: "counterClusterMarkerSmall",
                  color: "white",
                }
              };

              const algorithmOptions = {
                maxZoom: 8
              };

              const markerClustererOptions = {
                map: this.$refs.map.$mapObject, 
                markers:markers,
                renderer: {
                  render: 
                    function (cluster, clusterStatus) {
                      const mean = clusterStatus.clusters.markers.mean;
                      let label;
                      if(mean <= cluster.count){
                        label = clusterLabelLarge(cluster.count);
                      }else{
                        label = clusterLabelSmall(cluster.count);
                      }

                      return  new google.maps.Marker({
                        position: cluster.position,
                        label: label,
                        icon: clusterImage
                      });
                  },
                },
                algorithmOptions: algorithmOptions

              }
              if (this.cluster) {
                this.cluster.clearMarkers();      
              }
              this.cluster = new MarkerClusterer(markerClustererOptions);

            });
          }
        }
      }
    },

    computed: {
      google: gmapApi,
      ...mapState([
        'loading',
        'viewModal',
        'listModal',
        'points',
        'mood',
        'totalArtwork',
        'mapFilters',
        'modalImages',
        'modalTotalImages',
        "charities"
      ]),
      ...mapGetters([
        'filterPoints'
      ])
    },

    methods: {
      ...mapMutations([
        'openViewModal',
        'closeViewModal',
        'openListModal',
        'closeListModal',
        'setAsset',
        'setFileFilters',
        'setCharityFilters',
        'setMoodFilters',
        'setRatingFilters',
        "setCharities"
      ]),

      ...mapActions([
        'fetchPoints',
        'getAssetDetails',
        'fetchLocationArtwork',
        "fetchCharities"
      ]),

      setFiltersFile: function(values) {
        this.setFileFilters(values)
        this.fetchPoints()
      },

      setFiltersCharity: function(values) {
        this.setCharityFilters(values)
        this.fetchPoints()
      },

      setFiltersMood: function(values) {
        this.setMoodFilters(values)
        this.fetchPoints()
      },
      
      setFiltersRating: function(values) {
        this.setRatingFilters(values)
        this.fetchPoints()
      },

      getLocationArtwork: function (asset) {
        this.fetchLocationArtwork({lat:asset.lat,lng:asset.lng, page:1})
        this.currentLocation = {lat:asset.lat,lng:asset.lng}
        this.openListModal()
      },

      pagination: function (page) {
        let payload = this.currentLocation
        payload.page = page
        this.fetchLocationArtwork(payload)
      },

      closeModal: function() {
        this.$router.push({ path:`/map` })
        this.setAsset({})
        this.closeViewModal()
      },
      
      loadModal: function(id) {
        this.$router.push({ path:`/map/${id}` })
        // this.closeListModal()
      },

      addMarkerClickListener: function(marker, asset) {
        marker.addListener('click', () => {
          if(asset.count > 1){
            this.getLocationArtwork(asset);
          }else{
            this.$router.push({ path:`/map/${asset.id}` })
          }
        });
      },

      getAllCharities: function() {
            this.fetchCharities(this.page)
            .then(() => {
                this.setCharities(this.charities)
            });
        },
    }
  }
</script>


